<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="100px"
    >
      <el-form-item label="类型" prop="type">
        <el-select
          v-model="dataForm.type"
          placeholder="请选择类型"
          style="width: 100%"
          clearable
        >
          <el-option
            v-for="(item, index) in ['通知', '通报']"
            :key="item"
            :label="item"
            :value="index"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标题" prop="title">
        <el-input v-model="dataForm.title" placeholder="标题"></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <editor ref="editor" v-model="dataForm.content"></editor>
      </el-form-item>
      <el-form-item label="发布时间" prop="releaseDate">
        <el-date-picker
          style="width: 100%"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="dataForm.releaseDate"
          type="datetime"
          placeholder="选择发布时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="发布人名字" prop="releaseName">
        <el-input
          v-model="dataForm.releaseName"
          placeholder="发布人名字"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Editor from "@/components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        uid: "",
        title: "",
        type: "",
        content: "",
        releaseDate: "",
        releaseName: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
      },
      dataRule: {
        type: [{ required: true, message: "类型不能为空", trigger: "blur" }],
        title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
        content: [{ required: true, message: "内容不能为空", trigger: "blur" }],
        releaseDate: [
          { required: true, message: "发布时间不能为空", trigger: "blur" },
        ],
        releaseName: [
          { required: true, message: "发布人名字不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    init(id) {
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.$http.notice.info({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;
              this.$refs.editor.destroy();
              this.$refs.editor.init(this.dataForm.content);
            }
          });
        } else {
          this.$refs.editor.destroy();
          this.$refs.editor.init(this.dataForm.content);
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;

          if (this.dataForm.id) {
            // 修改
            this.$http.notice.update(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.notice.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
